import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import connectionMethods from './modules/connectionMethods'
import friends from './modules/friends'
import meetings from './modules/meetings'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    baseUrl: process.env.VUE_APP_BASE_URL
  },
  modules: {
    user,
    connectionMethods,
    friends,
    meetings
  },
  mutations: {
    logout () {
      this.commit('user/logoutUser')
      this.commit('connectionMethods/clearMethods')
      this.commit('meetings/clearMeetings')
    }
  },
  paths: ['user/state'],
  strict: debug,
  plugins: [createPersistedState()]
})
