<template>
  <v-layout align-top justify-center class="mt-5">
    <v-flex xs12 sm12 md8>
      <v-card class="elevation-6">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="display-1">{{this.meeting.name}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert id="errorView" v-show="error && error != ''" type="error">{{error}}</v-alert>
          <v-row v-if="userState.name == meeting.owner">
            <v-col class="ml-4" cols="auto">
              <div class="segment-header main-header">EINSTELLUNGEN</div>
              <div class="ml-3">
                <v-checkbox
                  :input-value="meeting.everyoneCanEdit"
                  @change="toggleEveryoneCanEdit()"
                  label="Jeder Teilnehmer darf die Teilnehmer bearbeiten"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-4" cols="auto">
              <div class="segment-header main-header">ORTE</div>
              <div class="ml-3">
                <div class="segment-header">HINZUFÜGEN</div>
                <add-location @error="error = $event" class="ml-3" :meeting="meeting"></add-location>
                <div class="segment-header">ALLE ORTE</div>
                <locations
                  class="ml-3"
                  @error="error = $event"
                  :meeting="meeting"
                  :locations="meeting.locations"
                ></locations>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-4" cols="12">
              <div class="segment-header main-header">ZEITEN</div>
              <div class="ml-3">
                <div class="segment-header">HINZUFÜGEN</div>
                <add-time @error="error = $event" class="ml-3" :meeting="meeting"></add-time>
                <div class="segment-header">ALLE ZEITEN</div>
                <times
                  class="ml-3"
                  @error="error = $event"
                  :meeting="meeting"
                  :times="meeting.times"
                ></times>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="segment-header main-header">TEILNEHMER</div>
              <v-dialog width="500" v-model="showEditParticipants" v-if="canEditParticipants">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" class="ml-3 mb-3">Teilnehmer bearbeiten</v-btn>
                </template>
                <edit-meeting-participants
                  @close="showEditParticipants = !$event"
                  :meeting="meeting"
                ></edit-meeting-participants>
              </v-dialog>
              <span v-if="!canEditParticipants">
                <v-chip-group column>
                  <v-chip
                    v-for="user in meeting.participants"
                    :key="user"
                    outlined
                    :to="'/profile/' + user"
                  >{{user}}</v-chip>
                </v-chip-group>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mr-4" cols="12">
              <comments @error="error = $event" :meeting="meeting" :comments="meeting.comments"></comments>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { extractError } from '../../webhelper/requests';
import LocationComponent from './Locations.vue';
import CommentsVue from './Comments.vue';
import AddLocationVue from './AddLocation.vue';
import TimesVue from './Times.vue';
import AddTimeVue from './AddTime.vue';
import EditMeetingParticipantsVue from './EditMeetingParticipants.vue';

export default {
  name: "MeetingComponent",
  components: {
    'comments': CommentsVue,
    'locations': LocationComponent,
    'add-location': AddLocationVue,
    'times': TimesVue,
    'add-time': AddTimeVue,
    'edit-meeting-participants': EditMeetingParticipantsVue
  },
  data: function () {
    return {
      meeting: { name: "N/A", comments: [] },
      showEditParticipants: false,
      error: ""
    }
  },
  methods: {
    toggleEveryoneCanEdit () {
      this.$store.dispatch('meetings/setEveryoneCanEdit', {
        id: this.meetingId, everyoneCanEdit: !this.meeting.everyoneCanEdit
      }).then(() => this.error = '')
        .catch(error => this.error = extractError(error))
    }
  },
  computed: {
    meetingId: function () {
      return this.$route.params.id;
    },
    userState: function () {
      return this.$store.state.user;
    },
    canEditParticipants: function () {
      return this.userState.name == this.meeting.owner || this.meeting.everyoneCanEdit;
    }
  },
  watch: {
    error: function () {
      if (this.error) {
        this.$nextTick(() => {
          document.getElementById("errorView").scrollIntoView({ behavior: "smooth", block: "center" });
        })
      }
    }
  },
  mounted: function () {
    this.$store.dispatch('meetings/fetchMeeting', this.meetingId)
      .then(it => this.meeting = it)
      .catch(error => this.error = extractError(error))
  }
}
</script>

<style scoped>
</style>