<template>
  <div>
    <v-text-field class="mx-5 mb-2" v-model="search" append-icon="search" label="Suche" single-line></v-text-field>

    <v-data-table ref="table" :items="people" :headers="headers" :search="search">
      <template v-slot:[`header.name`]="{ header }">
        <span class="title">{{ header.text }}</span>
      </template>
      <template v-slot:[`header.aktionen`]="{ header }">
        <span class="title">{{ header.text }}</span>
      </template>

      <template v-slot:body=" { items }">
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td class="subheading text-center">{{ item.name }}</td>
            <td class="subheading box-container">
              <v-checkbox
                class="checkbox"
                :input-value="value"
                @change="$emit('input', $event)"
                hide-details
                :value="item.name"
                :key="item.name"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getAllPeople } from '../../webhelper/peopleList'
import { extractError } from '../../webhelper/requests'

export default {
  name: 'FriendAddDialog',
  data: function () {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'center' },
        { text: 'Aktionen', value: 'aktionen', sortable: false, align: 'center' }
      ],
      people: [],
    }
  },
  methods: {
  },
  props: {
    title: String,
    value: {
      default: () => [],
      type: Array
    }
  },
  mounted: function () {
    getAllPeople()
      .then(it => {
        this.people = it.map(name => ({ name: name }));
        this.$emit('error', '');
      })
      .catch(error => this.$emit('error', extractError(error)))
  }
}
</script>

<style>
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
