<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="formValid">
            <v-text-field
              @keydown.enter.prevent="login"
              prepend-icon="person"
              name="login"
              label="Dein Name (Titel sind glaube ich dabei, Zweitnamen eher nicht)"
              type="text"
              v-model="userName"
              :rules="[notEmpty]"
            ></v-text-field>
            <v-text-field
              @keydown.enter.prevent="login"
              prepend-icon="lock"
              name="password"
              label="Passwort"
              type="password"
              v-model="password"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-form>
          <p class="error-message" :display="error.length !== 0">{{ error }}</p>
          <router-link class="link" :to="{ name: 'PasswordResetRequest' }">Passwort vergessen?</router-link>
          <a class="mt-3 link" @click="sendEmail">Sende mir eine E-Mail!</a>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="login" :disabled="!formValid">Login</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { extractError } from "../webhelper/requests.js";

export default {
  name: "LoginComponent",
  data: function() {
    return {
      userName: "",
      password: "",
      error: "",
      formValid: false,
      email: "aHNqZ19sLHVfYWZyY3A+bm1xcmNtLGJj"
    };
  },
  methods: {
    login: function() {
      if (!this.formValid) {
        return;
      }

      this.$store
        .dispatch("user/login", {
          userName: this.userName,
          password: this.password
        })
        .then(() => {
          this.error = "";

          let redirectUrl = "/profile";

          if (this.$route.query.redirect) {
            let attemptedUrl = this.$route.query.redirect;

            if (
              attemptedUrl.indexOf("http") >= 0 ||
              !attemptedUrl.startsWith("/")
            ) {
              // eslint-disable-next-line no-console
              console.log("Invalid redirect received: " + attemptedUrl);
            } else {
              redirectUrl = attemptedUrl;
            }
          }

          this.$router.push(redirectUrl);
        })
        .catch(e => {
          this.error = extractError(e);
        });
      return "";
    },
    notEmpty: function(input) {
      return input.length === 0 ? "Dieses Feld darf nicht leer sein!" : true;
    },
    sendEmail: function() {
      let slightlyDecoded = window.atob(this.email);

      let result = "";
      for (let i = 0; i < slightlyDecoded.length; i++) {
        result += String.fromCharCode(slightlyDecoded.charCodeAt(i) + 2);
      }

      window.location.href = "mailto:" + result;
    }
  },
  mounted: function() {
    this.$emit("hide-nav-bar", true);
  },
  destroyed: function() {
    this.$emit("hide-nav-bar", false);
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 1.2rem;
  text-align: center;
}

.link:hover {
  font-size: 1.1rem;
  color: var(--primary);
}

.link {
  display: block;
  text-align: center;
  color: gray;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  transition: font-size 0.2s ease-in-out, color 0.2s ease-in-out;
  height: 24px;
}
</style>
