import axios from "axios";
import { isJwtValid } from "@/webhelper/requests.js";

// initial state
const state = {
  name: "",
  accessToken: null,
  refreshToken: null,
  isRefreshTokenValid() {
    return isJwtValid(this.refreshToken);
  }
};

// getters
const getters = {
  loggedIn: state => state.refreshToken !== ""
};

// actions
const actions = {
  login({ commit, dispatch }, { userName, password }) {
    return axios
      .post("login", {
        username: userName,
        password: password
      })
      .then(it => {
        let newState = {
          name: userName,
          refreshToken: it.data["token"]
        };

        commit("loginUser", newState);
        return dispatch("fetchAccessToken");
      });
  },
  fetchAccessToken({ commit, state }) {
    return axios
      .post("login/getAccessToken", { refreshToken: state.refreshToken })
      .then(response => {
        commit("setAccessToken", response.data.token);
        return response.data.token;
      });
  }
};

// mutations
const mutations = {
  loginUser(state, userInfo) {
    state.name = userInfo.name;
    state.refreshToken = userInfo.refreshToken;
  },
  setAccessToken(state, token) {
    state.accessToken = token;
  },
  logoutUser(state) {
    state.name = "";
    state.accessToken = "";
    state.refreshToken = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
