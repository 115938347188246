import axios from "axios";

export function getAllEntriesForUser(user) {
  return axios
    .get("connection-method", {
      params: {
        name: user
      }
    })
    .then(res => res.data)
    .then(methods =>
      methods.map(method => {
        method["visibility"] = method["visibility"].toLowerCase();
        return method;
      })
    );
}

export function deleteMethod(method) {
  return axios.post("connection-method/delete", method);
}

export function addMethod(method) {
  return axios.put("connection-method", method);
}

export function editMethod(oldMethod, newMethod) {
  return axios.post("connection-method/edit", {
    old: oldMethod,
    new: newMethod
  });
}
