import axios from "axios";

/**
 * Returns the friends as an array of `{name: <name>}` objects.
 */
export function getFriends() {
  return axios.get("friends").then(it => {
    return it.data.map(function(it) {
      return { name: it };
    });
  });
}

export function deleteFriend(friend) {
  return axios.post("friends/delete", friend);
}

export function addFriends(friends) {
  return axios.post("friends/add", friends);
}
