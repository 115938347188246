<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Passwort ändern</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <change-password-fields
            :changePassword="changePassword"
            @input="password = $event"
            @error="readyToCommit = !$event"
          ></change-password-fields>
          <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
          <v-alert :value="successMessage.length !== 0" type="success">{{ successMessage }}</v-alert>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="changePassword" :disabled="!readyToCommit">Ändern</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { changePassword } from '../../webhelper/changePassword.js'
import { extractError } from '../../webhelper/requests.js'
import ChangePasswordFieldsVue from './ChangePasswordFields.vue'

export default {
  components: {
    'change-password-fields': ChangePasswordFieldsVue
  },
  data: function () {
    return {
      password: '',
      error: '',
      successMessage: '',
      readyToCommit: false
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    changePassword: function () {
      if (!this.readyToCommit) {
        return
      }
      this.error = ''
      this.successMessage = ''

      changePassword(this.password)
        .then(() => { this.successMessage = 'Passwort geändert' })
        .catch(error => { this.error = extractError(error) })
    }
  }
}
</script>

<style scoped>
</style>
