<template>
  <v-dialog persistent v-model="dialogVisible" width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" slot="activator">Hinzufügen</v-btn>
    </template>

    <v-card class="text-center">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="headline">Methode</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="formValid">
          <v-text-field
            @keydown.enter.prevent="save"
            v-model="methodCopy.name"
            label="Dienst"
            :rules="[notEmpty]"
          ></v-text-field>
          <v-text-field
            @keydown.enter.prevent="save"
            v-model="methodCopy.value"
            label="Wert"
            :rules="[notEmpty]"
          ></v-text-field>
          <visibility-display id="visibility-display" v-model="methodCopy.visibility" label="Test"></visibility-display>
        </v-form>
      </v-card-text>

      <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save" :disabled="!readyToCommit">Speichern</v-btn>
        <v-btn dark color="red lighten-1" @click="close">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VisibilityDisplay from './VisibilityDisplay'
import { extractError } from '../webhelper/requests.js'
import { setTimeout } from 'timers';

export default {
  name: 'ConnectionMethodDialog',
  data: function () {
    return {
      dialogVisible: false,
      error: '',
      editing: false,
      // has an *indirect* dependency on method, so a computed property doesn't quite cut it
      methodCopy: { name: '', value: '', visibility: 'public' },
      formValid: false,
      existsAlready: false
    }
  },
  computed: {
    methods: function () {
      return this.$store.state.connectionMethods.methods
    },

    readyToCommit: function () {
      return !this.existsAlready && this.formValid
    }
  },
  watch: {
    dialogVisible: function () {
      if (this.dialogVisible) {
        setTimeout(() => {
          this.$refs.form.reset()
        })
      }
    },
    method: function (value) {
      setTimeout(() => {
        this.methodCopy = Object.assign({}, value)
      });
    },
    'methodCopy': {
      handler () {
        if (this.editing) {
          return
        }
        let amount = this.methods.filter((it) => it.name === this.methodCopy.name && it.value === this.methodCopy.value).length

        if (amount !== 0) {
          this.error = 'Diesen Eintrag gibt es bereits. Bitte bearbeite ihn stattdessen!'
          this.existsAlready = true
        } else {
          this.error = ''
          this.existsAlready = false
        }
      },
      deep: true
    }
  },
  methods: {
    openForEdit: function () {
      this.editing = true
      this.dialogVisible = true
    },
    save: function () {
      if (!this.readyToCommit) {
        return
      }

      if (this.editing) {
        this.$store.dispatch('connectionMethods/editMethod', {
          oldMethod: this.method,
          newMethod: this.methodCopy
        })
          .then(() => { this.close() })
          .catch(error => { this.error = extractError(error) })
      } else {
        this.$store.dispatch('connectionMethods/addMethod', this.methodCopy)
          .then(() => { this.close() })
          .catch(error => { this.error = extractError(error) })
      }
    },
    close: function () {
      this.dialogVisible = false
      this.error = ''
      this.editing = false
      this.$emit('closed')
    },
    notEmpty: function (input) {
      return !input || input.length === 0 ? 'Dieses Feld darf nicht leer sein!' : true
    }
  },
  props: {
    method: Object
  },
  components: {
    'visibility-display': VisibilityDisplay
  }
}
</script>

<style scoped>
.v-dialog .headline {
  font-weight: bold;
}
#label-visibility .v-label {
  text-align: start;
}
#visibility-display {
  width: 100%;
  margin-top: 1em;
}
</style>
