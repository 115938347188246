// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import Router from 'vue-router'
import vuetify from './plugins/vuetify';
import store from './store'
import axios from 'axios'
import { isJwtValid } from './webhelper/requests'

Vue.config.productionTip = false

Vue.use(Router)
Vue.use(Vuex)

axios.defaults.baseURL = store.state.baseUrl

axios.interceptors.request.use(
  async request => {
    if (!store.state.user.accessToken) {
      return Promise.resolve(request);
    }

    let navigatesToLogin = request.url && request.url.indexOf("login") >= 0;
    if (!(navigatesToLogin) && !isJwtValid(store.state.user.accessToken) && store.state.user.refreshToken) {
      await store.dispatch("user/fetchAccessToken");
    }

    // eslint-disable-next-line require-atomic-updates
    request.headers['Authorization'] = 'Bearer ' + store.state.user.accessToken;
    return Promise.resolve(request)
  }
)

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  template: '<App/>',
  render: h => h(App)
}).$mount("#app")
