<template>
  <bubble-diagram
    :meeting="meeting"
    :elements="meeting.locations"
    :canDelete="canDelete"
    :deleteElement="deleteLocation"
    :vote="vote"
    :elementToBubble="locationToBubble"
    :editBubble="editBubble"
    :showElementVoteDialog="showLocationVoteDialog"
    :currentEditBubble="currentEditBubble"
    @setVoteDialogVisiblity="showLocationVoteDialog = $event"
  >
    <template #tooltipContent="{ bubble, translateVote }">
      <span class="bubble-tooltip-name">{{bubble.name}}</span>
      <table class="mt-2 bubble-vote-table">
        <tr>
          <td>Ja</td>
          <td>{{ bubble.yes }}</td>
          <td>{{ formatPeople(bubble.yesPeople) }}</td>
        </tr>
        <tr>
          <td>Vielleicht</td>
          <td>{{ bubble.maybe }}</td>
          <td>{{ formatPeople(bubble.maybePeople) }}</td>
        </tr>
        <tr>
          <td>Nein</td>
          <td>{{ bubble.no }}</td>
          <td>{{ formatPeople(bubble.noPeople) }}</td>
        </tr>
        <tr v-if="bubble.userVote">
          <td class="pt-3" style="font-weight: bold">Deine Wahl</td>
          <td class="pt-3">{{ translateVote(bubble.userVote) }}</td>
        </tr>
        <tr>
          <td class="pt-3">Von</td>
          <td class="pt-3">{{ bubble.creator }}</td>
        </tr>
      </table>
      <br />
    </template>
  </bubble-diagram>
</template>


<script>
import { Bubble } from "./MathUtils";
import { extractError } from '../../webhelper/requests';
import BubbleDiagramVue from './BubbleDiagram.vue';

export default {
  components: {
    'bubble-diagram': BubbleDiagramVue
  },
  name: "LocationBubbles",
  data: function () {
    return {
      showLocationVoteDialog: false,
      currentEditBubble: null,
    }
  },
  methods: {
    formatPeople (people) {
      return people.map(person => person.user).join(", ")
    },
    locationToBubble (location) {
      let yes = location.acceptance.filter(it => it.acceptanceLevel == "YES")
      let maybe = location.acceptance.filter(it => it.acceptanceLevel == "MAYBE")
      let no = location.acceptance.filter(it => it.acceptanceLevel == "NO")

      let userVote = location.acceptance.filter(it => it.user == this.userState.name)[0] || null

      if (userVote) {
        userVote = userVote.acceptanceLevel;
      }

      return new Bubble(location.name, yes, maybe, no, userVote, location.creator, location.id);
    },
    editBubble: function (bubble) {
      this.currentEditBubble = bubble;
      this.showLocationVoteDialog = true;
    },
    vote: function (option) {
      if (!this.currentEditBubble) {
        return;
      }
      this.showLocationVoteDialog = false;
      this.$store.dispatch('meetings/voteForLocation', {
        meetingId: this.meetingId || null,
        locationId: this.currentEditBubble.id,
        acceptanceLevel: option
      })
        .then(() => this.$emit('error', null))
        .catch(error => this.$emit('error', extractError(error)))
    },
    canDelete: function (bubble) {
      if (!bubble) {
        return false;
      }
      return bubble.creator == this.userState.name || this.meeting.owner == this.userState.name;
    },
    deleteLocation: function (bubble) {
      this.showLocationVoteDialog = false;
      this.$store
        .dispatch('meetings/deleteLocation', { meetingId: this.meetingId, locationId: bubble.id })
        .then(() => this.$emit('error', null))
        .catch(error => this.$emit('error', extractError(error)))
    }
  },
  watch: {
  },
  computed: {
    userState: {
      get () {
        return this.$store.state.user
      }
    },
    meetingId: {
      get () {
        return this.meeting.id;
      }
    },
  },
  props: {
    meeting: Object,
    locations: {
      default: function () { return []; },
      type: Array
    },
  }
}
</script>

<style scoped>
.bubble-tooltip-name {
  font-weight: bold;
  font-variant: small-caps;
  text-align: center;
  display: block;
}

.bubble-vote-table td {
  padding: 0px 5px 0px 5px;
  max-width: 50vw;
}

/* Values are in the second td */
.bubble-vote-table > tr > td:nth-child(even) {
  text-align: end;
}
/* Labels are in the first td */
.bubble-vote-table > tr > td:nth-child(odd) {
  font-variant: small-caps;
}
</style>