<template>
  <v-app id="app">
    <navigation-bar :hidden="hideNavBar"></navigation-bar>
    <v-main>
      <v-container fluid fill-height>
        <!-- Key is set so the component will be recreated when the path changes. -->
        <!-- The profile view needs this to fetch the correct data -->
        <router-view :key="$route.fullPath" @hide-nav-bar="handleNavBarVisibilityChange" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'

export default {
  name: 'KeepInTouchMain',
  components: {
    'navigation-bar': NavigationBar
  },
  data: function () {
    return {
      hideNavBar: false
    }
  },
  methods: {
    handleNavBarVisibilityChange (hideNavBar) {
      this.hideNavBar = hideNavBar
    }
  }
}
</script>

<style>
* {
  --primary: #4caf50;
  --primary-darken-1: #2d9437;
}

.clickable-icon {
  cursor: pointer;
}

.clickable-icon:hover {
  opacity: 0.5;
}

.main-header {
  font-weight: bolder !important;
}

.segment-header {
  height: 48px;

  margin-top: 8px;
  display: flex;
  align-items: center;

  font-size: 0.875rem;
  font-variant: small-caps;
}
</style>
