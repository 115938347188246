import axios from "axios";

// initial state
const state = {
  meetings: []
};

// getters
const getters = {};

// actions
const actions = {
  fetchMeetings({ commit }) {
    return axios.get("meetings").then(it => {
      const meetings = it.data;

      commit("setMeetings", meetings);
      return meetings;
    });
  },
  fetchMeeting({ state, commit }, id) {
    return axios.get(`/meetings/${id}`).then(it => {
      commit("setMeeting", it.data);
      // return cached meeting so changes are reflected
      return state.meetings.filter(it => it.id == id)[0];
    });
  },
  addMeeting({ commit }, { name, participants }) {
    return axios
      .post("meetings/addMeeting", {
        name: name,
        participants: participants
      })
      .then(it => {
        commit("setMeeting", it.data);
        return it.data;
      });
  },
  deleteMeeting({ commit }, { id }) {
    return axios
      .post("meetings/deleteMeeting", {
        id: id
      })
      .then(() => {
        commit("deleteMeeting", id);
        return true;
      });
  },
  setParticipants({ commit }, { id, participants }) {
    return axios
      .post("meetings/setParticipants", {
        meetingId: id,
        participants: participants
      })
      .then(it => {
        commit("setParticipants", { meetingId: id, participants: it.data });
        return true;
      });
  },
  setEveryoneCanEdit({ commit }, { id, everyoneCanEdit }) {
    return axios
      .post("meetings/everyoneCanEdit", {
        meetingId: id,
        everyoneCanEdit: everyoneCanEdit
      })
      .then(() => {
        commit("setEveryoneCanEdit", {
          meetingId: id,
          everyoneCanEdit: everyoneCanEdit
        });
        return true;
      });
  },
  postComment({ commit }, { meetingId, text }) {
    return axios
      .post("meetings/postComment", {
        meetingId: meetingId,
        text: text
      })
      .then(it => {
        commit("setComments", { meetingId: meetingId, comments: it.data });
        return true;
      });
  },
  deleteComment({ commit }, { commentId }) {
    return axios
      .post("meetings/deleteComment", {
        commentId: commentId
      })
      .then(() => {
        commit("deleteComment", { commentId: commentId });
        return true;
      });
  },
  addLocation({ commit }, { meetingId, name }) {
    return axios
      .post("meetings/addLocation", {
        meetingId: meetingId,
        name: name
      })
      .then(it => {
        commit("setLocations", { meetingId: meetingId, locations: it.data });
      });
  },
  deleteLocation({ commit }, { meetingId, locationId }) {
    return axios
      .post("meetings/deleteLocation", {
        meetingId: meetingId,
        elementId: locationId
      })
      .then(it => {
        commit("setLocations", { meetingId: meetingId, locations: it.data });
        return true;
      });
  },
  voteForLocation({ commit }, { meetingId, locationId, acceptanceLevel }) {
    return axios
      .post("meetings/setLocationAcceptance", {
        meetingId: meetingId,
        elementId: locationId,
        acceptanceLevel: acceptanceLevel
      })
      .then(it => {
        commit("setMeeting", it.data);
        return true;
      });
  },
  addTime({ commit }, { meetingId, time }) {
    return axios
      .post("meetings/addTime", {
        meetingId: meetingId,
        epochMillis: time.getTime()
      })
      .then(it => {
        commit("setTimes", { meetingId: meetingId, times: it.data });
        return true;
      });
  },
  deleteTime({ commit }, { meetingId, timeId }) {
    return axios
      .post("meetings/deleteTime", {
        meetingId: meetingId,
        elementId: timeId
      })
      .then(it => {
        commit("setTimes", { meetingId: meetingId, times: it.data });
        return true;
      });
  },
  voteForTime({ commit }, { meetingId, timeId, acceptanceLevel }) {
    return axios
      .post("meetings/setTimeAcceptance", {
        meetingId: meetingId,
        elementId: timeId,
        acceptanceLevel: acceptanceLevel
      })
      .then(it => {
        commit("setMeeting", it.data);
        return true;
      });
  }
};

// mutations
const mutations = {
  setMeetings(state, meetings) {
    state.meetings = meetings;
  },
  setMeeting(state, meeting) {
    let foundMeetings = state.meetings.filter(it => it.id == meeting.id);

    if (foundMeetings.length == 0) {
      state.meetings.push(meeting);
    } else {
      Object.assign(foundMeetings[0], meeting);
    }
  },
  deleteMeeting(state, id) {
    state.meetings = state.meetings.filter(it => it.id != id);
  },
  setComments(state, { meetingId, comments }) {
    state.meetings
      .filter(it => it.id == meetingId)
      .forEach(meeting => (meeting.comments = comments));
  },
  deleteComment(state, { commentId }) {
    state.meetings.forEach(
      meeting =>
        (meeting.comments = meeting.comments.filter(it => it.id != commentId))
    );
  },
  setLocations(state, { meetingId, locations }) {
    state.meetings
      .filter(it => it.id == meetingId)
      .forEach(meeting => (meeting.locations = locations));
  },
  setTimes(state, { meetingId, times }) {
    state.meetings
      .filter(it => it.id == meetingId)
      .forEach(meeting => (meeting.times = times));
  },
  setParticipants(state, { meetingId, participants }) {
    state.meetings
      .filter(it => it.id == meetingId)
      .forEach(meeting => (meeting.participants = participants));
  },
  setEveryoneCanEdit(state, { meetingId, everyoneCanEdit }) {
    state.meetings
      .filter(it => it.id == meetingId)
      .forEach(meeting => (meeting.everyoneCanEdit = everyoneCanEdit));
  },
  clearMeetings(state) {
    state.meetings = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
