<template>
  <v-layout align-top justify-center class="mt-5">
    <v-flex xs12 sm8>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="display-1">{{ displayedUser }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table class="elevation-1" :headers="headers" hide-default-footer :items="methods">
            <template v-slot:[`header.dienst`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="title">{{ header.text }}</span>
                </template>
                <span>{{headerToolstips[header.text]}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.wert`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="title">{{ header.text }}</span>
                </template>
                <span>{{headerToolstips[header.text]}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.sichtbarkeit`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="title">{{ header.text }}</span>
                </template>
                <span>{{headerToolstips[header.text]}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`header.aktion`]="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="title">{{ header.text }}</span>
                </template>
                <span>{{headerToolstips[header.text]}}</span>
              </v-tooltip>
            </template>

            <template v-slot:body=" { items }">
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="subheading text-center">{{ item.name }}</td>
                  <td class="subheading text-center">{{ item.value }}</td>
                  <td
                    v-if="methodsEditable"
                    class="subheading text-center visibility-display-container"
                  >
                    <visibility-display read-only :value="item.visibility"></visibility-display>
                  </td>
                  <td v-if="methodsEditable" class="subheading text-center">
                    <v-btn text icon class="mx-0" @click="editItem(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn text icon class="mx-0" color="red lighten-1" @click="deleteItem(item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>

          <connection-method-dialog
            v-if="methodsEditable"
            ref="connectionAdd"
            :method="editedItem"
            @closed="onCloseDialogReceived"
          ></connection-method-dialog>

          <v-spacer></v-spacer>
        </v-card-actions>

        <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import VisibilityDisplay from './VisibilityDisplay'
import ConnectionMethodDialog from './ConnectionMethodDialog'
import { extractError } from '../webhelper/requests.js'

export default {
  name: 'ProfileComponent',
  data: function () {
    return {
      headerToolstips: {
        'Dienst': 'Der Dienst (z.B. Twitter, Telefon, Adresse, etc)',
        'Wert': 'Der Wert dazu (z.B. Handle, Telefonnummer, etc.)',
        'Sichtbarkeit': 'Wer alles den Eintrag sehen kann',
        'Aktionen': 'Verschiedene Aktionen, die auf die Zeile angewendet werden'
      },
      editedItem: { name: '', value: '', visibility: 'public' },
      error: '',
      headerValues: ['dienst', 'wert', 'sichtbarkeit', 'aktion']
    }
  },
  computed: {
    headers: function () {
      return [
        { text: 'Dienst', value: 'dienst', sortable: false, align: 'center', showWhenReadOnly: true },
        { text: 'Wert', value: 'wert', sortable: false, align: 'center', showWhenReadOnly: true },
        { text: 'Sichtbarkeit', value: 'sichtbarkeit', sortable: false, align: 'center', showWhenReadOnly: false },
        { text: 'Aktionen', value: 'aktion', sortable: false, align: 'center', showWhenReadOnly: false }
      ]
        .filter(it => it.showWhenReadOnly || this.methodsEditable)
    },
    displayedUser: function () {
      return this.$route.params.name || this.userState.name
    },
    methodsEditable: function () {
      return this.displayedUser === this.userState.name
    },
    userState: {
      get () {
        return this.$store.state.user
      }
    },
    methods: function () {
      return this.$store.state.connectionMethods.methods
    }
  },
  methods: {
    fetchMethods: function () {
      this.$store.dispatch('connectionMethods/fetchMethodsForUser', this.displayedUser)
        .then(() => {
          this.error = ''
        })
        .catch(error => { this.error = extractError(error) })
    },
    onCloseDialogReceived: function () {
      this.editedItem = { name: '', value: '', visibility: 'public' }
    },
    editItem: function (item) {
      this.editedItem = Object.assign({}, item)

      this.$refs.connectionAdd.openForEdit()
    },
    deleteItem: function (item) {
      if (confirm('Möchtest du dies wirklich löschen?')) {
        this.$store.dispatch('connectionMethods/deleteMethod', item)
          .then(() => { this.error = '' })
          .catch(error => { this.error = extractError(error) })
      }
    }
  },
  components: {
    'visibility-display': VisibilityDisplay,
    'connection-method-dialog': ConnectionMethodDialog
  },
  mounted: function () {
    this.fetchMethods()
  }
}
</script>

<style scoped>
.visibility-display-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
