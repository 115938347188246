<template>
  <v-layout align-top justify-center class="mt-5">
    <v-flex xs12 sm8 md5>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="display-1">Stufenliste</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mx-5 mb-2"
            v-model="search"
            append-icon="search"
            label="Suche"
            single-line
          ></v-text-field>

          <v-data-table
            ref="table"
            :items="people"
            :headers="headers"
            :search="search"
            :sort-by.sync="options.sortBy"
            :options.sync="options"
          >
            <template v-slot:[`header.firstName`]="{ header }">
              <span class="title">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.lastName`]="{ header }">
              <span class="title">{{ header.text }}</span>
            </template>

            <template v-slot:body=" { items }">
              <tbody>
                <router-link
                  class="clickable"
                  v-for="item in items"
                  :key="item.name"
                  tag="tr"
                  :to="url(item)"
                >
                  <td class="subheading text-center">{{ item.firstName }}</td>
                  <td class="subheading text-center">{{ item.lastName }}</td>
                </router-link>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>

        <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { getAllPeople } from '../webhelper/peopleList.js'
import { extractError } from '../webhelper/requests.js'

export default {
  name: 'PeopleListComponent',
  data: function () {
    return {
      error: '',
      loading: true,
      people: [],
      search: '',
      headers: [
        { text: 'Vorname', value: 'firstName', align: 'center' },
        { text: 'Nachname', value: 'lastName', align: 'center' }
      ],
      options: {},
    }
  },
  methods: {
    url: function (person) {
      return '/profile/' + person.firstName + ' ' + person.lastName;
    },

    restoreState: function () {
      let state = window.history.state

      this.options.rowsPerPage = state.rowsPerPage || 5
      this.search = state.search || ''
      this.options.sortBy = state.sortBy || 'lastName'
      this.options.descending = state.descending || false

      // Ugly hack to make this work. The reason is likely that the items are set and on the next tick it is updated
      // So we delay updating yet again.
      // https://github.com/vuetifyjs/vuetify/issues/3766 will hopefully make this less hacky
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.options.page = state.page || 1
        })
      })
    }
  },
  mounted: function () {
    getAllPeople()
      .then(it => {
        return it.map(person => {
          let split = person.split(' ')
          if (split.length === 1) {
            return { firstName: person, lastName: '' }
          }
          return { firstName: split[0], lastName: split.slice(1, split.length).join(' ') }
        })
      })
      .then(it => {
        this.people = it
      })
      .catch(it => {
        this.error = extractError(it)
      })
      .finally(() => {
        this.loading = false

        this.restoreState()
      })
  },
  beforeRouteLeave: function (to, from, next) {
    let newState = {}
    Object.assign(newState, window.history.state)

    newState.rowsPerPage = this.options.rowsPerPage
    newState.page = this.options.page
    newState.search = this.search
    newState.sortBy = this.options.sortBy
    newState.descending = this.options.descending

    window.history.replaceState(newState, 'stufenliste')

    next()
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
