import { getAllEntriesForUser, deleteMethod, addMethod, editMethod } from '../../webhelper/profile.js'

// initial state
const state = {
  methods: []
}

// getters
const getters = {
}

// actions
const actions = {
  fetchMethodsForUser ({ commit }, userName) {
    return getAllEntriesForUser(userName)
      .then(entries => {
        commit('setMethods', entries)
        return entries
      })
  },

  deleteMethod ({ commit }, method) {
    return deleteMethod(method)
      .then(result => {
        commit('deleteMethod', method)
        return result
      })
  },

  addMethod ({ commit }, method) {
    return addMethod(method)
      .then(result => {
        commit('addMethod', method)
        return result
      })
  },

  editMethod ({ commit }, change) {
    return editMethod(change.oldMethod, change.newMethod)
      .then(result => {
        commit('editMethod', change)
        return result
      })
  }
}

// mutations
const mutations = {
  clearMethods (state) {
    state.methods = []
  },

  setMethods (state, methods) {
    state.methods = methods

    sortMethods(state.methods)
  },

  deleteMethod (state, method) {
    let index = state.methods.indexOf(method)
    state.methods.splice(index, 1)
  },

  addMethod (state, method) {
    state.methods.push(method)

    sortMethods(state.methods)
  },

  editMethod (state, { oldMethod, newMethod }) {
    let stored = state.methods.filter(it => it.name === oldMethod.name && it.value === oldMethod.value).pop()

    Object.assign(stored, newMethod)

    sortMethods(state.methods)
  }
}

function sortMethods (array) {
  array.sort((a, b) => {
    if (a.name.localeCompare(b.name) !== 0) {
      return a.name.localeCompare(b.name)
    }
    return a.value.localeCompare(b.value)
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
