<template>
  <v-list>
    <v-subheader class="main-header">KOMMENTARE</v-subheader>
    <v-row cols="auto" class="pl-4 mr-6">
      <v-col>
        <v-textarea
          placeholder="Kommentar verfassen..."
          class="comment-text-area"
          auto-grow
          rows="1"
          row-height="30px"
          v-model="commentDraftText"
        ></v-textarea>
      </v-col>
      <v-col class="clickable-icon" cols="1" align-self="center" justify-self="center">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon @click="postComment(commentDraftText)" v-on="on">send</v-icon>
          </template>
          Senden
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="mb-3">
      <v-list-item class="py-0 my-0" v-for="comment in comments" :key="comment.id">
        <v-list-item-content class="py-0 my-0">
          <comment :meeting="meeting" :comment="comment"></comment>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import { extractError } from '../../webhelper/requests'
import CommentComponentVue from './CommentComponent.vue'

export default {
  components: {
    'comment': CommentComponentVue,
  },
  name: "CommentsComponent",
  data: function () {
    return {
      commentDraftText: ""
    }
  },
  methods: {
    postComment: function (commentText) {
      this.$store
        .dispatch('meetings/postComment', { meetingId: this.meetingId, text: commentText })
        .then(() => this.commentDraftText = "")
        .catch(error => this.$emit('error', extractError(error)))
    },
  },
  computed: {
    meetingId: {
      get () {
        return this.meeting.id
      }
    }
  },
  props: {
    comments: Array,
    meeting: Object
  }
}
</script>

<style>
.comment-text-area textarea {
  line-height: 1.3 !important;
}
</style>