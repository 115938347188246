<template>
  <v-card class="elevation-6">
    <v-toolbar dark color="primary">
      <v-toolbar-title class="display-1">Teilnehmer bearbeiten</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <span class="segment-header">TEILNEHMER</span>
      <user-selection-component @error="error = $event" v-model="participants"></user-selection-component>
      <v-alert v-if="error" type="error">{{ error }}</v-alert>
    </v-card-text>
    <v-card-actions class="pb-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="setParticipants">Änderungen übernehmen</v-btn>
      <v-btn color="red" dark @click="$emit('close', true)">Abbrechen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserSelectionComponentVue from '../util/UserSelectionComponent.vue';
import { extractError } from '../../webhelper/requests';

export default {
  components: {
    'user-selection-component': UserSelectionComponentVue
  },
  name: 'EditMeetingComponent',
  data: function () {
    return {
      error: "",
      participants: []
    }
  },
  watch: {
    meeting: {
      handler: function () {
        // Copy it
        this.participants = this.meeting.participants.filter(() => true)
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    setParticipants () {
      this.$store.dispatch('meetings/setParticipants', { id: this.meeting.id, participants: this.participants })
        .then(() => {
          this.$emit('close', true)
          this.error = ""
        })
        .catch(error => this.error = extractError(error))
    }
  },
  props: {
    meeting: Object
  }
}
</script>

<style scoped>
</style>