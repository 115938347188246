<template>
  <v-dialog persistent width="800" v-model="dialogVisible">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" slot="activator">Freund hinzufügen</v-btn>
    </template>
    <v-card class="text-center">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="headline">Freund hinzufügen</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          class="mx-5 mb-2"
          v-model="search"
          append-icon="search"
          label="Suche"
          single-line
        ></v-text-field>

        <v-data-table ref="table" :items="people" :headers="headers" :search="search">
          <template v-slot:[`header.name`]="{ header }">
            <span class="title">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.aktionen`]="{ header }">
            <span class="title">{{ header.text }}</span>
          </template>

          <template v-slot:body=" { items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td class="subheading text-center">{{ item.name }}</td>
                <td class="subheading box-container">
                  <v-checkbox
                    class="checkbox"
                    v-model="selectedPeople"
                    hide-details
                    :value="item.name"
                    :key="item.name"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">Hinzufügen</v-btn>
        <v-btn dark color="red" @click="close">Abbrechen</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import { extractError } from '../webhelper/requests'
import { getAllPeople } from '../webhelper/peopleList'

export default {
  name: 'FriendAddDialog',
  data: function () {
    return {
      dialogVisible: false,
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'center' },
        { text: 'Aktionen', value: 'aktionen', sortable: false, align: 'center' }
      ],
      people: [
        { name: 'Test' }
      ],
      selectedPeople: [],
      error: ''
    }
  },
  methods: {
    close: function () {
      this.selectedPeople = []
      this.dialogVisible = false
    },
    save: function () {
      this.$store.dispatch('friends/addFriends', this.selectedPeople)
        .catch(error => { this.error = extractError(error) })

      this.close()
    }
  },
  mounted: function () {
    getAllPeople()
      .then(it => { this.people = it.map(name => ({ name: name })) })
      .catch(it => { this.error = extractError(it) })
  }
}
</script>

<style>
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
