<template>
  <v-layout align-top justify-center class="mt-5">
    <v-flex xs12 sm8 md5>
      <v-card class="elevation-6">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="display-1">Treffen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-iterator
            :items="allMeetings"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{ itemsPerPageOptions }"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="meeting in props.items"
                  :key="meeting.id"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <v-card :to="'/meeting/' + meeting.id">
                    <v-card-title class="meeting-title-container">
                      <h4 class="meeting-title">{{ meeting.name }}</h4>
                      <v-btn
                        v-if="canDeleteMeeting(meeting)"
                        class="delete-button"
                        color="red"
                        @click.stop.prevent="deleteMeeting(meeting)"
                        icon
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list>
                      <v-subheader>ERSTELLER</v-subheader>
                      <v-list-item class="no-hover">
                        <v-list-item-content>
                          <v-chip-group>
                            <v-chip outlined :to="'/profile/' + meeting.owner">{{meeting.owner}}</v-chip>
                          </v-chip-group>
                        </v-list-item-content>
                      </v-list-item>

                      <v-subheader>TEILNEHMER</v-subheader>
                      <v-list-item class="no-hover">
                        <v-list-item-content>
                          <v-chip-group column class="flowing-chip-group">
                            <v-chip
                              v-for="user in limitParticipants(getParticipants(meeting))"
                              :key="user"
                              outlined
                              :to="'/profile/' + user"
                            >{{user}}</v-chip>
                            <v-chip
                              class="faded"
                              v-if="getParticipants(meeting).length > 4"
                              outlined
                            >+ {{ getParticipants(meeting).length - 4 }}</v-chip>
                          </v-chip-group>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-dialog width="500" v-model="showAddDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" class="mb-3">Neues Treffen erstellen</v-btn>
            </template>
            <create-meeting @close="showAddDialog = !$event"></create-meeting>
          </v-dialog>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import CreateMeetingVue from './CreateMeeting.vue';
import { extractError } from '../../webhelper/requests';

export default {
  components: {
    'create-meeting': CreateMeetingVue
  },
  name: 'MeetingComponent',
  data: function () {
    return {
      itemsPerPage: 4,
      itemsPerPageOptions: [4, 8, 12],
      showAddDialog: false,
      error: ""
    }
  },
  methods: {
    limitParticipants (participants) {
      return participants.slice(0, Math.min(4, participants.length));
    },
    getParticipants (meeting) {
      return meeting.participants;
    },
    canDeleteMeeting (meeting) {
      return this.userState.name == meeting.owner;
    },
    deleteMeeting (meeting) {
      if (!confirm(`Möchtest du '${meeting.name}' wirklich löschen?`)) {
        return;
      }

      this.$store.dispatch('meetings/deleteMeeting', { id: meeting.id })
        .then(() => this.error = "")
        .catch(error => this.error = extractError(error))
    }
  },
  computed: {
    allMeetings: function () {
      return this.$store.state.meetings.meetings;
    },
    userState: function () {
      return this.$store.state.user
    }
  },
  mounted: function () {
    this.$store.dispatch("meetings/fetchMeetings")
      .catch(error => this.error = extractError(error))
  }
}
</script>

<style scoped>
.faded {
  opacity: 0.6;
}
.no-hover {
  cursor: default;
}

.meeting-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting-title-container > .meeting-title {
  flex: 1 1;
}
.meeting-title-container > .delete-button {
  justify-content: flex-end;
}
</style>