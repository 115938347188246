<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Einstellungen bearbeiten</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <span class="segment-header">E-MAIL BENACHRICHTIGUNGEN</span>
          <v-alert type="warning">
            Durch einen Konfigurationsfehler wurden in den letzten 2 Tagen unnötigerweise E-Mails versendet.
            Dieser Fehler sollte behoben sein und Mails nur noch ein mal im Jahr verschickt werden.
          </v-alert>
          <v-checkbox
            class="ml-5"
            :indeterminate="emailSettings.wantsEmails === undefined"
            v-model="emailSettings.wantsEmails"
            label="Ich möchte E-Mail Benachrichtigungen für Treffen erhalten"
            hide-details
          ></v-checkbox>
          <v-checkbox
            class="ml-5"
            :indeterminate="emailSettings.wantsReminderEmails === undefined"
            v-model="emailSettings.wantsReminderEmails"
            label="Ich möchte periodische Erinnerungsmails erhalten"
            hide-details
          ></v-checkbox>

          <span class="segment-header">PASSWORT</span>
          <v-btn class="ml-5" :to="{ name: 'ChangePassword' }" color="primary">Passwort ändern</v-btn>

          <v-alert class="mt-5" :value="error.length !== 0" type="error">{{ error }}</v-alert>
          <v-alert
            class="mt-5"
            :value="successMessage.length !== 0"
            type="success"
          >{{ successMessage }}</v-alert>
        </v-card-text>
        <v-card-actions class="mt-4 pr-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">Speichern</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { extractError } from "../../webhelper/requests";

export default {
  name: "SettingsComponent",
  data: function() {
    return {
      emailSettings: {
        wantsEmails: undefined,
        wantsReminderEmails: undefined
      },
      error: "",
      successMessage: ""
    };
  },
  methods: {
    save() {
      axios
        .post("modify-user/email-settings", this.emailSettings)
        .then(() => {
          this.error = "";
          this.successMessage = "Gespeichert!";

          setTimeout(() => {
            this.successMessage = "";
          }, 2000);
        })
        .catch(error => (this.error = extractError(error)));
    }
  },
  mounted: function() {
    axios
      .get("query-user/email-settings")
      .then(it => {
        this.emailSettings = it.data;
        this.error = "";
      })
      .catch(error => (this.error = extractError(error)));
  }
};
</script>

<style scoped>
</style>
