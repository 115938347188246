<template>
  <v-container class="comment-container">
    <v-row>
      <span class="author">{{comment.user}}</span>
    </v-row>
    <v-row>
      <v-col class="pa-0 flex-grow-1" align-self="center">
        <span class="comment">{{processedContent}}</span>
        <div
          @click="showAll = true"
          v-if="shouldTruncate && !showAll"
          class="mt-2 overflow"
        >Mehr anzeigen</div>
        <div
          @click="showAll = false"
          v-if="shouldTruncate && showAll"
          class="mt-2 overflow"
        >Weniger anzeigen</div>
      </v-col>
      <v-col class="py-0 pl-3 pr-0 flex-grow-0 d-flex justify-end" align-self="center">
        <v-dialog width="500" v-model="showDelete">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="clickable-icon" color="red">delete</v-icon>
          </template>
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Kommentar löschen</v-card-title>
            <v-card-text>Möchtest du den Kommentar wirklich löschen?</v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn @click="deleteComment()" color="primary" text>Ja</v-btn>
              <div class="flex-grow-1"></div>
              <v-btn @click="showDelete = false" color="red" dark text>Nein</v-btn>
              <div class="flex-grow-1"></div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { extractError } from "../../webhelper/requests";
export default {
  name: "CommentComponent",
  data: function() {
    return {
      showAll: false,
      showDelete: false
    };
  },
  computed: {
    userState: function() {
      return this.$store.state.user;
    },
    processedContent: function() {
      let content = this.comment.content;

      if (this.showAll) {
        return content;
      }

      let trimmed = this.trimToFitScore(content, this.maxLength);
      return this.shouldTruncate ? trimmed + "…" : trimmed;
    },
    shouldTruncate: function() {
      return (
        this.comment.content.length >
        this.trimToFitScore(this.comment.content, this.maxLength).length
      );
    },
    canDelete: function() {
      return (
        this.comment.user == this.userState.name ||
        this.userState.name == this.meeting.owner
      );
    }
  },
  methods: {
    trimToFitScore(content, maxScore) {
      let result = "";

      let score = 0;
      for (let i = 0; i < content.length; i++) {
        let character = content.charAt(i);

        if (score > maxScore) {
          return result;
        }
        if (character == "\n") {
          score += 20;
        }
        score++;

        if (score <= maxScore || character != "\n") {
          result += character;
        }
      }

      return result;
    },
    deleteComment() {
      this.showDelete = false;
      this.$store
        .dispatch("meetings/deleteComment", { commentId: this.comment.id })
        .then(() => {
          this.$emit("error", null);
        })
        .catch(error => this.$emit("error", extractError(error)));
    }
  },
  props: {
    meeting: Object,
    comment: Object,
    maxLength: {
      default: 300,
      type: Number
    }
  }
};
</script>

<style scoped>
.comment-container {
  min-width: 250px;
}
.author {
  color: #060607;
  padding-bottom: 5px;
  font-weight: bold;
}
.comment {
  white-space: pre-wrap;
  flex-basis: 100%;
  user-select: text;
}
.overflow {
  color: #606060;
  font-weight: bold;
  cursor: pointer;
}
.overflow:hover {
  text-decoration: underline;
}
</style>