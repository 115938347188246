<template>
  <div class="scroll-container">
    <div
      class="container"
      :style="{ 
        minHeight: minHeight + 'px',
        minWidth: minWidth + 'px'
      }"
    >
      <v-dialog
        width="700"
        attach=".container"
        @input="$emit('setVoteDialogVisiblity', $event)"
        :value="showElementVoteDialog"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Was hältst du von '{{ (currentEditBubble || {}).name }}'?
            <div class="flex-grow-1"></div>
            <v-icon class="clickable-icon" @click="$emit('setVoteDialogVisiblity', false)">close</v-icon>
          </v-card-title>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="primary" text @click="vote('YES')">Ja</v-btn>
            <v-btn text @click="vote('MAYBE')">Vielleicht</v-btn>
            <v-btn color="red" text @click="vote('NO')">Nein</v-btn>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" text @click="vote(null)">Zurückziehen</v-btn>
            <v-btn
              v-if="canDelete(currentEditBubble)"
              color="red"
              text
              @click="deleteElement(currentEditBubble)"
            >Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <span
        :class=" [ 'bubble', 'bubble-vote-' + (bubble.userVote ? bubble.userVote.toLowerCase() : 'none')]"
        :style="{ 
          left: bubble.position.x - bubble.getDiameter() / 2 + 'px',
          top: bubble.position.y  - bubble.getDiameter() / 2 + 'px',
          width: bubble.getDiameter()+1 + 'px',
          height: bubble.getDiameter()+1 + 'px',
          maxWidth: bubble.getDiameter()+1 + 'px',
          maxHeight: bubble.getDiameter()+1 + 'px'
        }"
        :id="'bubble' + bubble.id"
        v-for="bubble in bubbles"
        :key="bubble.id"
        @click="editBubble(bubble)"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <canvas
              v-on="on"
              :ref="'bubble' + bubble.id"
              :width="bubble.getDiameter() + 1 + 'px'"
              :height="bubble.getDiameter() + 1 + 'px'"
            ></canvas>
            <span v-on="on" class="bubble-content">
              <span class="text px-2">{{ bubble.name }}</span>
            </span>
          </template>
          <span>
            <slot name="tooltipContent" :bubble="bubble" :translateVote="translateVote"></slot>
          </span>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>


<script>
import { Vector2D, toRadians } from "./MathUtils";

export default {
  name: "BubbleDiagram",
  data: function () {
    return {
    }
  },
  methods: {
    layout (bubbles) {
      let angle = 0;
      let startAngle = 0;
      let currentMaxRadius = 0;
      let levelRadius = 0;

      const padding = 5;
      let placementRadii = new Map(); // Map<Double, List<Bubble>> 

      let center = new Vector2D(this.width / 2, this.height / 2);

      for (let i = 0; i < bubbles.length; i++) {
        let bubble = bubbles[i];
        if (levelRadius == 0) {
          bubble.position = center;
          levelRadius = bubble.getDiameter() / 2.0;
          continue;
        }

        let radius = levelRadius + padding + bubble.getDiameter() / 2.0;
        currentMaxRadius = Math.max(currentMaxRadius, bubble.getDiameter() / 2.0);

        angle = this.placeBubble(center, angle, bubble, radius);

        let storedRadii = placementRadii.get(levelRadius) || []
        storedRadii.push(bubble);
        placementRadii.set(levelRadius, storedRadii)

        if (angle > Math.PI * 2 && angle - Math.PI * 2 + toRadians(10) > startAngle) {
          // remove bubble
          placementRadii.set(levelRadius, placementRadii.get(levelRadius).filter(it => it.id != bubble.id));

          levelRadius += currentMaxRadius * 2 + padding;
          currentMaxRadius = 0;
          angle -= Math.PI * 2;
          startAngle = angle;

          // re-place last bubble
          i--;
        } else {
          // Padding
          angle += toRadians(10);
        }
      }

      for (let [key, value] of placementRadii) {
        let spacerAmount = value.length - 1;
        let perCircle = (360.0 - spacerAmount * padding) / value.length;
        perCircle = toRadians(perCircle);

        let currentAngle = this.randomOffset ? Math.floor(Math.random() * 2 * Math.PI) : 0

        value.forEach(bubble => {
          let radius = key + padding + bubble.getDiameter() / 2.0;
          let resultX = center.x + Math.cos(currentAngle) * radius;
          let resultY = center.y + Math.sin(currentAngle) * radius;
          bubble.position = new Vector2D(resultX, resultY);
          currentAngle += perCircle;
          currentAngle += toRadians(padding);
        })
      }
    },
    placeBubble (center, angle, bubble, radius) {
      // Covered angle: https://math.stackexchange.com/a/3190374

      let xPosition = Math.cos(angle) * radius;
      let yPosition = Math.sin(angle) * radius;
      let centerPosition = new Vector2D(xPosition, yPosition).add(center);
      bubble.position = centerPosition;

      let r = bubble.getDiameter() / 2.0;
      let d = centerPosition.distanceTo(center);
      let rho = r / d;
      let bd = rho * Math.sqrt(1 - rho * rho);

      let coveredAngle = Math.asin(bd * d / Math.sqrt(d * d - r * r)) * 2;
      angle += coveredAngle;
      return angle;
    },
    fillCircle: function (contextObj, x, y, r) {
      contextObj.beginPath();
      contextObj.arc(x, y, r, 0, 2 * Math.PI);
      contextObj.fill();
    },
    translateVote: function (vote) {
      switch (vote) {
        case "YES": return "Ja"
        case "MAYBE": return "Vielleicht"
        case "NO": return "Nein"
        default: return vote;
      }
    }
  },
  watch: {
  },
  computed: {
    userState: {
      get () {
        return this.$store.state.user
      }
    },
    meetingId: {
      get () {
        return this.meeting.id;
      }
    },
    bounds: {
      get () {
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;

        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;

        // Find bounds
        for (let bubble of this.bubbles) {
          minX = Math.min(minX, bubble.position.x - bubble.getDiameter());
          maxX = Math.max(maxX, bubble.position.x + bubble.getDiameter());
          minY = Math.min(minY, bubble.position.y - bubble.getDiameter());
          maxY = Math.max(maxY, bubble.position.y + bubble.getDiameter());
        }
        return {
          minX: minX,
          maxX: maxX,
          minY: minY,
          maxY: maxY
        }
      }
    },
    minWidth: {
      get () {
        let bounds = this.bounds;
        return bounds.maxX - bounds.minX;
      }
    },
    minHeight: {
      get () {
        let bounds = this.bounds;
        return bounds.maxY - bounds.minY;
      }
    },
    bubbles: {
      get () {
        let bubbles = this.elements.map(it => this.elementToBubble(it))

        if (bubbles.length == 0) {
          return []
        }

        let maxVoteCount = bubbles
          .map(bubble => bubble.yes + bubble.maybe)
          .reduce((a, b) => Math.max(a, b))
        if (maxVoteCount == 0) {
          maxVoteCount = 1;
        }
        bubbles.forEach(bubble => bubble.maxVoteCount = maxVoteCount)

        bubbles.sort((a, b) => {
          if (a.getDiameter() == b.getDiameter()) {
            return 0;
          }

          return a.getDiameter() > b.getDiameter() ? -1 : 1;
        })

        this.layout(bubbles)

        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;

        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;

        // Find bounds
        for (let bubble of bubbles) {
          minX = Math.min(minX, bubble.position.x - bubble.getDiameter());
          maxX = Math.max(maxX, bubble.position.x + bubble.getDiameter());
          minY = Math.min(minY, bubble.position.y - bubble.getDiameter());
          maxY = Math.max(maxY, bubble.position.y + bubble.getDiameter());
        }

        // translate coordinates
        for (let bubble of bubbles) {
          bubble.position.x -= minX;
          bubble.position.y -= minY;
        }

        this.$nextTick(() => {
          for (let bubble of bubbles) {
            let totalNiceVotes = bubble.yes + bubble.maybe;
            let canvas = this.$refs['bubble' + bubble.id][0]
            let context = canvas.getContext('2d');

            context.fillStyle = 'gray';

            // outline
            context.beginPath();
            let radius = bubble.getDiameter() / 2;
            context.arc(radius, radius, radius, 0, 2 * Math.PI);
            context.fill();

            context.fillStyle = '#35c644';
            let yesAngle = (360.0 / totalNiceVotes) * bubble.yes;

            context.beginPath();
            context.lineTo(radius, radius);
            context.arc(radius, radius, radius, 0, toRadians(yesAngle));
            context.lineTo(radius, radius);
            context.closePath();
            context.fill();

            context.fillStyle = '#2d9437';
            context.beginPath();
            context.lineTo(radius, radius);
            context.arc(radius, radius, radius, toRadians(yesAngle), Math.PI * 2);
            context.lineTo(radius, radius);
            context.closePath();
            context.fill();
          }
        })

        return bubbles
      }
    }
  },
  props: {
    meeting: Object,
    elements: {
      default: function () { return []; },
      type: Array
    },
    width: {
      default: 500,
      type: Number
    },
    height: {
      default: 500,
      type: Number
    },
    randomOffset: {
      default: false,
      type: Boolean
    },
    canDelete: Function, // bubble parameter
    deleteElement: Function, // bubble parameter
    vote: Function, // option, bubble parameter
    elementToBubble: Function, // element parameter
    editBubble: Function, // bubble parameter
    showElementVoteDialog: {
      default: false,
      type: Boolean
    },
    currentEditBubble: Object
  }
}
</script>

<style scoped>
.container {
  position: relative;
  padding: 0px;
  margin: 0px;
}

.scroll-container {
  overflow: auto;
  padding: 0px;
  margin: 0px;
}

.bubble {
  position: absolute;

  animation: circle-popout-reverse 0.2s 1 linear;

  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  border-radius: 100%;
}

.bubble-content {
  color: white;
  font-weight: bold;
  font-variant: small-caps;

  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-content > .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Center it and allow overflow */
  white-space: pre-wrap;
  text-align: center;
  max-height: 100%;
  padding: 5px;
}

.bubble:hover {
  animation: circle-popout 0.2s 1 linear;
  transform: scale(1.2);
  z-index: 2;
}

.bubble-vote-none {
  /*box-shadow: 0px 0px 10px 12px rgba(52, 90, 170, 0.644); */
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 15px 5px #9ecaed;
}

@keyframes circle-popout {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes circle-popout-reverse {
  0% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(0.9);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
  }
}
</style>
