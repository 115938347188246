var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","color":"primary"},slot:"activator"},on),[_vm._v("Freund hinzufügen")])]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',{staticClass:"text-center"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"headline"},[_vm._v("Freund hinzufügen")])],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mx-5 mb-2",attrs:{"append-icon":"search","label":"Suche","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{ref:"table",attrs:{"items":_vm.people,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(header.text))])]}},{key:"header.aktionen",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(header.text))])]}},{key:"body",fn:function( ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"subheading text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"subheading box-container"},[_c('v-checkbox',{key:item.name,staticClass:"checkbox",attrs:{"hide-details":"","value":item.name},model:{value:(_vm.selectedPeople),callback:function ($$v) {_vm.selectedPeople=$$v},expression:"selectedPeople"}})],1)])}),0)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Hinzufügen")]),_c('v-btn',{attrs:{"dark":"","color":"red"},on:{"click":_vm.close}},[_vm._v("Abbrechen")]),_c('v-spacer')],1),_c('v-alert',{attrs:{"value":_vm.error.length !== 0,"type":"error"}},[_vm._v(_vm._s(_vm.error))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }