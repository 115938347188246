var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"mt-5",attrs:{"align-top":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":""}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.displayedUser))])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.methods},scopedSlots:_vm._u([{key:"header.dienst",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"title"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.headerToolstips[header.text]))])])]}},{key:"header.wert",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"title"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.headerToolstips[header.text]))])])]}},{key:"header.sichtbarkeit",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"title"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.headerToolstips[header.text]))])])]}},{key:"header.aktion",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"title"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.headerToolstips[header.text]))])])]}},{key:"body",fn:function( ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"subheading text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"subheading text-center"},[_vm._v(_vm._s(item.value))]),(_vm.methodsEditable)?_c('td',{staticClass:"subheading text-center visibility-display-container"},[_c('visibility-display',{attrs:{"read-only":"","value":item.visibility}})],1):_vm._e(),(_vm.methodsEditable)?_c('td',{staticClass:"subheading text-center"},[_c('v-btn',{staticClass:"mx-0",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"text":"","icon":"","color":"red lighten-1"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e()])}),0)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pr-3 pb-3"},[_c('v-spacer'),(_vm.methodsEditable)?_c('connection-method-dialog',{ref:"connectionAdd",attrs:{"method":_vm.editedItem},on:{"closed":_vm.onCloseDialogReceived}}):_vm._e(),_c('v-spacer')],1),_c('v-alert',{attrs:{"value":_vm.error.length !== 0,"type":"error"}},[_vm._v(_vm._s(_vm.error))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }