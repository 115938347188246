<template>
  <v-form v-model="formValid" ref="change-password-form">
    <v-text-field
      @keydown.enter.prevent="changePassword(password)"
      prepend-icon="lock"
      name="password"
      label="Passwort"
      type="password"
      v-model="password"
      :rules="[notEmpty]"
    ></v-text-field>
    <v-text-field
      @keydown.enter.prevent="changePassword(password)"
      prepend-icon="lock"
      name="confirm"
      label="Passwort bestätigen"
      type="password"
      v-model="passwordConfirm"
      :error="!passwordsMatch"
      :error-messages="confirmFieldErrorMessage"
    ></v-text-field>
  </v-form>
</template>

<script>

export default {
  data: function () {
    return {
      formValid: false,
      password: '',
      passwordConfirm: '',
      error: ''
    }
  },
  watch: {
    readyToCommit: function () {
      if (this.readyToCommit) {
        this.$emit('error', '')
        this.$emit('input', this.password)
      } else if (!this.passwordsMatch) {
        this.$emit('error', 'Die Passwörter stimmen nicht überein')
      } else if (!this.formValid) {
        this.$emit('error', 'Das Passwort ist ungültig')
      }
    },
    password: function () {
      this.$emit('input', this.password)
    },
    value: function () {
      this.password = this.value;

      if (this.value == "") {
        // Delay so listeners update correctly?
        this.$nextTick(() => {
          this.passwordConfirm = this.value
          this.$refs['change-password-form'].reset();
        })
      }
    }
  },
  computed: {
    passwordsMatch: function () {
      return this.password === this.passwordConfirm
    },

    confirmFieldErrorMessage: function () {
      return this.passwordsMatch ? '' : 'Die Passwörter stimmen nicht überein'
    },

    readyToCommit: function () {
      return this.formValid && this.passwordsMatch
    }
  },
  methods: {
    notEmpty: function (input) {
      return input.length === 0 ? 'Dieses Feld darf nicht leer sein!' : true
    },
    sameAsPassword: function (input) {
      return input !== this.password ? 'Die Passwörter stimmen nicht überein' : true
    }
  },
  props: {
    changePassword: Function, // password as param
    value: String
  }
}
</script>

<style scoped>
</style>
