<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <change-password-fields
            :changePassword="resetPassword"
            v-model="password"
            @error="readyToCommit = !$event"
          ></change-password-fields>
          <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
          <v-alert :value="successMessage.length !== 0" type="success">{{ successMessage }}</v-alert>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="resetPassword" :disabled="!readyToCommit">Ändern</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ChangePasswordFieldsVue from "../settings/ChangePasswordFields.vue";
import axios from "axios";
import { extractError } from "../../webhelper/requests";

export default {
  components: {
    "change-password-fields": ChangePasswordFieldsVue
  },
  name: "PasswordResetRequest",
  data: function() {
    return {
      password: "",
      error: "",
      successMessage: "",
      readyToCommit: false
    };
  },
  methods: {
    resetPassword() {
      if (!this.readyToCommit) {
        return;
      }

      axios
        .post("passwordResetFun/redeemToken", {
          token: this.token,
          newPassword: this.password
        })
        .then(() => {
          this.error = "";
          this.successMessage =
            "Passwort erfolgreich zurückgesetzt. Melde dich bitte erneut an.";
          this.password = "";
        })
        .catch(error => {
          this.successMessage = "";
          this.error = extractError(error);
          this.password = "";
        });
    }
  },
  computed: {
    token: function() {
      return this.$route.params.token;
    }
  },
  mounted: function() {
    this.$emit("hide-nav-bar", true);
  },
  destroyed: function() {
    this.$emit("hide-nav-bar", false);
  }
};
</script>

<style scoped>
</style>