import { getFriends, addFriends, deleteFriend } from '../../webhelper/friends'

// initial state
const state = {
  // array of `{name: <name>}`
  friends: []
}

// getters
const getters = {
}

// actions
const actions = {
  fetchFriends ({ commit }) {
    return getFriends()
      .then(it => {
        commit('setFriends', it)
        return it
      })
  },
  addFriends ({ commit }, friendNames) {
    return addFriends(friendNames)
      .then(it => {
        commit('addFriends', friendNames.map(name => ({ name: name })))
        return it
      })
  },
  deleteFriend ({ commit }, name) {
    return deleteFriend(name)
      .then(it => {
        commit('deleteFriend', { name: name })
        return it
      })
  }
}

// mutations
const mutations = {
  setFriends (state, friends) {
    state.friends = friends
  },
  addFriends (state, friendNames) {
    friendNames.forEach(it => {
      state.friends.push(it)
    })
  },
  deleteFriend (state, friend) {
    let indices = state.friends
      .map((it, index) => ({ value: it, index: index }))
      .filter(it => it.value.name === friend.name)
      .map(it => it.index)

    indices.forEach(index => {
      state.friends.splice(index, 1)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
