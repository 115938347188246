<template>
  <nav v-if="!hidden">
    <v-toolbar dark color="primary darken-1" class="hidden-sm-and-down">
      <v-toolbar-title>Keep In Touch</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text v-if="currentRoute.name !== 'PeopleList'" :to="{ name: 'PeopleList' }">
          Stufenliste
          <v-icon right dark>people</v-icon>
        </v-btn>
        <v-btn text v-if="currentRoute.name !== 'Profile'" :to="{ name: 'Profile' }">
          Profil
          <v-icon right dark>person</v-icon>
        </v-btn>
        <v-btn text v-if="currentRoute.name !== 'Friends'" :to="{ name: 'Friends' }">
          Freunde
          <v-icon right dark>explore</v-icon>
        </v-btn>
        <v-btn text v-if="currentRoute.name !== 'Meetings'" :to="{ name: 'Meetings' }">
          Treffen
          <v-icon right dark>event</v-icon>
        </v-btn>
        <v-btn text v-if="currentRoute.name !== 'Settings'" :to="{ name: 'Settings' }">
          Einstellungen
          <v-icon right dark>settings</v-icon>
        </v-btn>
        <v-btn text @click="logout">
          Logout
          <v-icon right dark>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- MOBILE -->
    <v-list dense dark id="nav-list" class="hidden-md-and-up elevation-6">
      <v-list-group>
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title id="main-title">Keep In Touch</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentRoute.name !== 'PeopleList'" :to="{ name: 'PeopleList' }">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">people</v-icon>Stufenliste
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentRoute.name !== 'Profile'" :to="{ name: 'Profile' }">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">person</v-icon>Profil
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentRoute.name !== 'Friends'" :to="{ name: 'Friends' }">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">explore</v-icon>Freunde
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentRoute.name !== 'Meetings'" :to="{ name: 'Meetings' }">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">event</v-icon>Treffen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="currentRoute.name !== 'Settings'" :to="{ name: 'Settings' }">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">settings</v-icon>Einstellungen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mx-3">exit_to_app</v-icon>Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  props: {
    hidden: Boolean
  },
  methods: {
    logout: function () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  },
  computed: {
    currentRoute: function () {
      return this.$route;
    }
  }
}
</script>

<style scoped>
#nav-list {
  background-color: var(--primary-darken-1);
}

#main-title {
  font-size: 20px;
  font-weight: 500;
}
</style>
