import Router from 'vue-router'
import Login from '@/components/Login'
import Profile from '@/components/Profile'
import ChangePassword from '@/components/settings/ChangePassword'
import PeopleList from '@/components/PeopleList'
import Friends from '@/components/Friends'
import Meetings from '@/components/meetings/Meetings'
import Meeting from '@/components/meetings/Meeting'
import PasswordResetRequest from '@/components/password_reset/PasswordResetRequest'
import PasswordResetPerform from '@/components/password_reset/PasswordResetPerform'
import Settings from '@/components/settings/Settings'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import store from '../store/index.js'
import Vue from 'vue'

let router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/profile'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: function () {
          return 'Login'
        }
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        title: function () {
          return 'Dein Profil'
        }
      }
    },
    {
      path: '/profile/:name',
      name: 'ProfileForUser',
      component: Profile,
      meta: {
        title: function (to) {
          return 'Profil: ' + to.params.name
        }
      }
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      meta: {
        title: function () {
          return 'Passwort ändern'
        }
      }
    },
    {
      path: '/people-list',
      name: 'PeopleList',
      component: PeopleList,
      meta: {
        title: function () {
          return 'Stufenliste'
        }
      }
    },
    {
      path: '/friends',
      name: 'Friends',
      component: Friends,
      meta: {
        title: function () {
          return 'Freunde'
        }
      }
    },
    {
      path: '/meetings',
      name: 'Meetings',
      component: Meetings,
      meta: {
        title: function () {
          return 'Treffen'
        }
      }
    },
    {
      path: '/meeting/:id',
      name: 'Meeting',
      component: Meeting,
      meta: {
        title: function (to) {
          return 'Treffen ' + to.params.id
        }
      }
    },
    {
      path: '/passwordResetRequest',
      name: 'PasswordResetRequest',
      component: PasswordResetRequest,
      meta: {
        title: function () {
          return 'Passwort zurücksetzen'
        }
      }
    },
    {
      path: '/passwordResetPerform/:token',
      name: 'PasswordResetPerform',
      component: PasswordResetPerform,
      meta: {
        title: function () {
          return 'Passwort zurücksetzen'
        }
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        title: function () {
          return 'Einstellungen'
        }
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Do not require auth for login with fragment navigation
  if (to.name == 'Login') {
    next()
    return
  }

  // Do not require auth for password resets
  if (to.name.startsWith("PasswordReset")) {
    next()
    return
  }

  if (!store.state.user.isRefreshTokenValid()) {
    next({
      name: 'Login',
      query: {
        redirect: to.path
      }
    })
  } else {
    next();
  }
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to && to.meta && to.meta.title) {
    Vue.nextTick(() => {
      document.title = to.meta.title(to)
    })
  }
})

export default router
