<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!done">
          <v-text-field placeholder="Nutzername" v-model="userName"></v-text-field>

          <div v-if="possibleMails.length > 0">
            <v-list>
              <v-subheader>BEKANNTE EMAILS</v-subheader>
              <v-list-item-group>
                <v-list-item v-for="email in possibleMails" :key="email">
                  <v-list-item-content>{{ email }}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-text-field
              v-model="chosenEmail"
              type="email"
              placeholder="Vervollständige eine der E-Mails"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-text v-if="done">
          <v-alert
            type="success"
            border="top"
            color="primary"
          >Eine reset email wurde an '{{ chosenEmail }}' gesendet.</v-alert>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            v-show="possibleMails.length == 0 && !done"
            color="primary"
            @click="fetchPossibleEmails()"
            :disabled="userName.length == 0"
          >E-Mails anfragen</v-btn>
          <v-btn
            v-show="possibleMails.length != 0 && !done"
            color="primary"
            @click="requestReset()"
            :disabled="chosenEmail.length == 0"
          >Passwort reset anfragen</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-alert v-show="error" type="error">{{ error }}</v-alert>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { extractError } from "../../webhelper/requests";

export default {
  name: "PasswordResetRequest",
  data: function() {
    return {
      userName: "",
      possibleMails: [],
      chosenEmail: "",
      error: "",
      done: false
    };
  },
  methods: {
    fetchPossibleEmails() {
      axios
        .post("passwordResetFun/fetchPossibleEmails", { user: this.userName })
        .then(it => {
          this.possibleMails = it.data;
          this.error = "";
        })
        .catch(error => (this.error = extractError(error)));
    },
    requestReset() {
      axios
        .post("passwordResetFun", {
          user: this.userName,
          email: this.chosenEmail
        })
        .then(() => {
          this.error = "";
          this.done = true;
        })
        .catch(error => (this.error = extractError(error)));
    }
  },
  mounted: function() {
    this.$emit("hide-nav-bar", true);
  },
  destroyed: function() {
    this.$emit("hide-nav-bar", false);
  }
};
</script>

<style scoped>
</style>