<template>
  <v-btn-toggle :value="value" mandatory class="d-flex">
    <v-btn text value="private" @click="clicked('private')" :class="buttonClass">
      <span>Privat</span>
      <v-icon right>lock</v-icon>
    </v-btn>
    <v-btn text value="friends" @click="clicked('friends')" :class="buttonClass">
      <span>Freunde</span>
      <v-icon right>people</v-icon>
    </v-btn>
    <v-btn text value="public" @click="clicked('public')" :class="buttonClass">
      <span>Stufe</span>
      <v-icon right>lock_open</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'VisibilityDisplayComponent',
  props: {
    value: String,
    readOnly: Boolean
  },
  computed: {
    buttonClass: function () {
      return this.readOnly ? 'read-only' : ''
    }
  },
  methods: {
    clicked (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.v-btn-toggle .v-btn {
  opacity: 1;
  height: 36px !important;
  flex: 1;
  min-width: 130px !important;
}

.read-only {
  pointer-events: none;
}

.v-btn-toggle .v-btn.v-btn--active {
  background-color: dodgerblue !important;
  color: white;
  border-radius: 2px;
}

.v-btn-toggle .v-btn.v-btn--active .v-icon {
  color: white !important;
}
</style>
