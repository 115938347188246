<template>
  <v-card class="elevation-6">
    <v-toolbar dark color="primary">
      <v-toolbar-title class="display-1">Treffen hinzufügen</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <span class="segment-header">NAME DES TREFFENS</span>
      <v-text-field
        @update:error="nameError = $event"
        v-model="name"
        :rules="[notEmpty]"
        placeholder="Name"
      ></v-text-field>
      <span class="segment-header">TEILNEHMER</span>
      <user-selection-component @error="error = $event" v-model="participants"></user-selection-component>
      <v-alert v-if="error" type="error">{{ error }}</v-alert>
    </v-card-text>
    <v-card-actions class="pb-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" :disabled="formHasError" @click="addMeeting">Erstellen</v-btn>
      <v-btn color="red" dark @click="$emit('close', true)">Abbrechen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserSelectionComponentVue from '../util/UserSelectionComponent.vue';
import { extractError } from '../../webhelper/requests';

export default {
  components: {
    'user-selection-component': UserSelectionComponentVue
  },
  name: 'CreateMeetingComponent',
  data: function () {
    return {
      nameError: true,
      participants: [],
      error: "",
      name: ""
    }
  },
  computed: {
    formHasError () {
      return this.nameError;
    }
  },
  methods: {
    notEmpty (input) {
      return input.length == 0 ? "Dieses Feld darf nicht leer sein" : true;
    },
    addMeeting () {
      this.$store.dispatch('meetings/addMeeting', { name: this.name, participants: this.participants })
        .then(() => {
          this.name = ""
          this.participants = []
          this.$emit('close', true)
          this.error = ""
        })
        .catch(error => this.error = extractError(error))
    }
  }
}
</script>

<style scoped>
</style>