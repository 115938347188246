<template>
  <v-row justify="start">
    <v-col cols="auto" justify-self="center" align-self="center">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateFormatted"
            label="Tag"
            hint="TT.MM.JJJJ format"
            persistent-hint
            prepend-icon="event"
            v-on="on"
            @blur="date = parseDate()"
            :rules="[hasDateFormatRule, isInFutureRule]"
            @update:error="inputHasError = $event"
          ></v-text-field>
        </template>
        <v-date-picker
          :min="new Date().toISOString().substring(0, 10)"
          color="primary"
          v-model="date"
          no-title
          @input="dateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-menu
        ref="timeMenu"
        v-model="timeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="time"
            label="Uhrzeit"
            prepend-icon="access_time"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          color="primary"
          format="24hr"
          v-model="time"
          @click:minute="$refs.timeMenu.save(time)"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col cols="1" justify-self="center" align-self="center">
      <v-icon v-show="!hasError" class="clickable-icon" @click="addTime()">add_circle_outline</v-icon>
      <v-tooltip v-show="hasError" right>
        <template v-slot:activator="{ on} ">
          <v-icon v-show="hasError" v-on="on" color="red">error_outline</v-icon>
        </template>
        {{ errorMessage }}
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { extractError } from '../../webhelper/requests'
export default {
  name: 'AddTimeComponent',
  data: function () {
    return {
      errorMessage: "",
      inputHasError: false,
      dateExceedsRange: false,

      dateMenu: false,
      timeMenu: false,

      date: new Date().toISOString().substring(0, 10),
      dateFormatted: this.reformatIsoDate(new Date().toISOString()),
      time: this.getCurrentTimeString(),
    }
  },
  computed: {
    combinedDateTime: function () {
      return this.combineDate(this.date, this.time);
    },
    hasError: function () {
      return this.inputHasError || this.dateExceedsRange;
    }
  },
  methods: {
    combineDate: function (date, time) {
      let [hours, minutes] = time.split(':');
      hours = this.zeroPadToLength(hours, 2);
      minutes = this.zeroPadToLength(minutes, 2);

      return new Date(`${date}T${hours}:${minutes}:00`);
    },
    getCurrentTimeString: function () {
      let hours = new Date().getHours();
      let minutes = new Date().getMinutes();

      return this.zeroPadToLength(hours, 2) + ':' + this.zeroPadToLength(minutes, 2);
    },
    hasDateFormatRule: function (input) {
      return /^\d{1,2}\.\d{1,2}\.\d{2,4}$/.test(input) ? true : "TT.MM.JJJJ";
    },
    isInFutureRule: function (date) {
      let parsedDate;
      if (date.indexOf('.') >= 0) {
        parsedDate = this.parseDate(date);
      } else {
        parsedDate = date;
      }
      let current = new Date();
      current.setHours(0, 0, 0, 0);
      return new Date(parsedDate) >= current ? true : "Der Tag liegt in der Vergangenheit";
    },
    reformatIsoDate: function (date) {
      const [year, month, day] = date.substring(0, 10).split('-');

      return `${day}.${month}.${year}`
    },
    parseDate: function () {
      let [day, month, year] = this.dateFormatted.split('.');

      if (!day || !month || !year) {
        return this.date;
      }

      if (year.length == 2) {
        year = "20" + year;
      }
      day = this.zeroPadToLength(day, 2);
      month = this.zeroPadToLength(month, 2);

      return `${year}-${month}-${day}`;
    },
    addTime: function () {
      if (this.inputHasError) {
        return;
      }
      this.$store.dispatch('meetings/addTime', {
        meetingId: this.meeting.id,
        time: this.combinedDateTime
      })
        .then(() => {
          this.$emit('error', null);
        })
        .catch(error => this.$emit('error', extractError(error)))
    },
    zeroPadToLength: function (string, length) {
      string = "" + string; // coerce to string
      if (string.length >= length) {
        return string;
      }
      let result = string;
      while (result.length < length) {
        result = '0' + result;
      }
      return result;
    }
  },
  watch: {
    date: function () {
      this.dateFormatted = this.reformatIsoDate(this.date)

      this.dateExceedsRange = this.combinedDateTime < new Date();
    },
    dateFormatted: function () {
      let combinedDateTime = this.combineDate(this.parseDate(this.dateFormatted), this.time);

      this.dateExceedsRange = combinedDateTime < new Date();
    },
    time: function () {
      let combinedDateTime = this.combineDate(this.parseDate(this.dateFormatted), this.time);

      this.dateExceedsRange = combinedDateTime < new Date();
    },
    dateExceedsRange: function () {
      if (this.dateExceedsRange) {
        this.errorMessage = "Der Zeitpunkt muss in der Zukunft liegen!";
      }
    },
    inputHasError: function () {
      if (this.inputHasError) {
        this.errorMessage = "Der Tag ist nicht korrekt eingegeben!";
      }
    }
  },
  props: {
    meeting: Object
  }
}
</script>

<style scoped>
</style>