export function extractError (axiosObject) {
  if (axiosObject.response) {
    return axiosObject.response.data['error'] || 'Unbekannter Fehler als Antwort (' + axiosObject.response.status + ')'
  } else if (axiosObject.request) {
    return 'Es konnte keine Verbindung zum Server aufgebaut werden'
  } else if (Object.prototype.toString.call(axiosObject) === '[object String]') { // it is a string
    return axiosObject;
  }
  else {
    return 'Fehler beim Erstellen der Anfrage'
  }
}

export function isJwtValid (jwtString) {
  if (!jwtString || jwtString.length === 0) {
    return false
  }

  return !isJwtExpired(jwtString)
}

function isJwtExpired (jwt) {
  let data = jwt.split('.')

  if (data.length !== 3) {
    return false
  }

  data = atob(data[1])
  data = JSON.parse(data)

  return data['exp'] && ((new Date()).getTime() / 1000) > data['exp']
}
