<template>
  <v-layout align-top justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="display-1">Freunde</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table ref="table" :items="friends" :headers="headers">
            <template v-slot:[`header.name`]="{ header }">
              <span class="title">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.aktionen`]="{ header }">
              <span class="title">{{ header.text }}</span>
            </template>

            <template v-slot:body=" { items }">
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="subheading text-center">{{ item.name }}</td>
                  <td class="subheading text-center">
                    <v-btn
                      text
                      icon
                      class="mx-0"
                      color="red lighten-1"
                      @click="deleteFriend(item.name)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pr-3 pb-3">
          <v-spacer></v-spacer>
          <friend-add-dialog></friend-add-dialog>
          <v-spacer></v-spacer>
        </v-card-actions>

        <v-alert :value="error.length !== 0" type="error">{{ error }}</v-alert>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { extractError } from '../webhelper/requests'
import FriendAddDialogVue from './FriendAddDialog.vue'

export default {
  name: 'Friends',
  data: function () {
    return {
      error: '',
      headers: [
        { text: 'Name', value: 'name', align: 'center' },
        { text: 'Aktionen', value: 'aktionen', sortable: false, align: 'center' }
      ]
    }
  },
  computed: {
    friends: function () {
      return this.$store.state.friends.friends
    }
  },
  methods: {
    fetchFriends () {
      this.$store.dispatch('friends/fetchFriends', {})
        .catch(error => { this.error = extractError(error) })
    },
    deleteFriend (name) {
      this.$store.dispatch('friends/deleteFriend', name)
        .catch(error => { this.error = extractError(error) })
    }
  },
  mounted: function () {
    this.fetchFriends()
  },
  components: {
    'friend-add-dialog': FriendAddDialogVue
  }
}
</script>

<style scoped>
</style>
