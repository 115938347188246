<template>
  <v-row justify="start">
    <v-col cols="auto" justify-self="center" align-self="center">
      <v-text-field v-model="name" placeholder="Name"></v-text-field>
    </v-col>
    <v-col cols="1" justify-self="center" align-self="center">
      <v-icon class="clickable-icon" @click="addLocation()">add_circle_outline</v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { extractError } from '../../webhelper/requests'
export default {
  name: 'AddLocationComponent',
  data: function () {
    return {
      name: ""
    }
  },
  methods: {
    addLocation: function () {
      this.$store.dispatch('meetings/addLocation', {
        meetingId: this.meeting.id,
        name: this.name
      })
        .then(() => {
          this.$emit('error', null);
          this.name = ''
        })
        .catch(error => this.$emit('error', extractError(error)))
    }
  },
  props: {
    meeting: Object
  }
}
</script>

<style scoped>
</style>