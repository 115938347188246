var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"container",style:({ 
      minHeight: _vm.minHeight + 'px',
      minWidth: _vm.minWidth + 'px'
    })},[_c('v-dialog',{attrs:{"width":"700","attach":".container","value":_vm.showElementVoteDialog},on:{"input":function($event){return _vm.$emit('setVoteDialogVisiblity', $event)}}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Was hältst du von '"+_vm._s((_vm.currentEditBubble || {}).name)+"'? "),_c('div',{staticClass:"flex-grow-1"}),_c('v-icon',{staticClass:"clickable-icon",on:{"click":function($event){return _vm.$emit('setVoteDialogVisiblity', false)}}},[_vm._v("close")])],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.vote('YES')}}},[_vm._v("Ja")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.vote('MAYBE')}}},[_vm._v("Vielleicht")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.vote('NO')}}},[_vm._v("Nein")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.vote(null)}}},[_vm._v("Zurückziehen")]),(_vm.canDelete(_vm.currentEditBubble))?_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.deleteElement(_vm.currentEditBubble)}}},[_vm._v("Löschen")]):_vm._e()],1)],1)],1),_vm._l((_vm.bubbles),function(bubble){return _c('span',{key:bubble.id,class:[ 'bubble', 'bubble-vote-' + (bubble.userVote ? bubble.userVote.toLowerCase() : 'none')],style:({ 
        left: bubble.position.x - bubble.getDiameter() / 2 + 'px',
        top: bubble.position.y  - bubble.getDiameter() / 2 + 'px',
        width: bubble.getDiameter()+1 + 'px',
        height: bubble.getDiameter()+1 + 'px',
        maxWidth: bubble.getDiameter()+1 + 'px',
        maxHeight: bubble.getDiameter()+1 + 'px'
      }),attrs:{"id":'bubble' + bubble.id},on:{"click":function($event){return _vm.editBubble(bubble)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('canvas',_vm._g({ref:'bubble' + bubble.id,refInFor:true,attrs:{"width":bubble.getDiameter() + 1 + 'px',"height":bubble.getDiameter() + 1 + 'px'}},on)),_c('span',_vm._g({staticClass:"bubble-content"},on),[_c('span',{staticClass:"text px-2"},[_vm._v(_vm._s(bubble.name))])])]}}],null,true)},[_c('span',[_vm._t("tooltipContent",null,{"bubble":bubble,"translateVote":_vm.translateVote})],2)])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }