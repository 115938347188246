export class Vector2D {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  add (other) {
    return new Vector2D(this.x + other.x, this.y + other.y)
  }

  subtract (other) {
    return new Vector2D(this.x - other.x, this.y - other.y)
  }

  distanceTo (other) {
    return Math.sqrt(this.subtract(other).normSquared())
  }

  normSquared () {
    return this.x * this.x + this.y * this.y;
  }
}

export class Bubble {

  constructor(name, yesPeople, maybePeople, noPeople, userVote, creator, id) {
    this.name = name;
    this.yesPeople = yesPeople;
    this.maybePeople = maybePeople;
    this.noPeople = noPeople;
    this.creator = creator
    this.id = id;
    this.userVote = userVote;
    this.position = new Vector2D(0, 0);
    this.maxVoteCount = this.maybe + this.yes;
  }

  get yes () {
    return this.yesPeople.length
  }

  get maybe () {
    return this.maybePeople.length
  }

  get no () {
    return this.noPeople.length
  }

  getDiameter () {
    // a * e^bx solved for a and b
    // with f(0) = base, f(maxVoteCount) = max and the rest interpolated
    let voteAmount = this.maybe + this.yes;
    let base = 50;
    let max = 150;
    let a = base;
    let b = Math.log(max / base) / this.maxVoteCount;

    return a * Math.exp(b * voteAmount);
  }
}
export function toRadians (angle) {
  return angle * (Math.PI / 180);
}

export function toDegrees (angle) {
  return angle * (180 / Math.PI);
}